import React from 'react';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkField,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { RemoveTrailingSlashes } from '../../utils/helper';
const htmlToReact = require('html-to-react');
const { Parser } = htmlToReact;
import InnerHTML from 'dangerously-set-html-content';

type PagePropertiesProps = {
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    route: {
      fields: {
        'Header Code': Field<string>;
        'Footer Code': Field<string>;
        'Meta Author': Field<string>;
        'Meta Description': Field<string>;
        'Meta Keywords': Field<string>;
        'Canonical Url': LinkField;
        'OG Image': {
          value: {
            src: string;
          };
        };
        'No Index': Field<boolean>;
        'No Follow': Field<boolean>;
      };
    };
    custom: {
      targetHostName: string;
    };
  };
};

const PageProperties = ({ sitecoreContext }: PagePropertiesProps): JSX.Element => {
  // Control should not render if page is in editing mode
  if (!sitecoreContext || sitecoreContext.pageEditing) {
    return <div className="PageProperties"></div>;
  }
  let parser = new Parser();
  let pageHeadInjection = sitecoreContext?.route?.fields['Header Code']?.value;
  let pageFooterInjection = sitecoreContext?.route?.fields['Footer Code']?.value;

  let keywords = sitecoreContext?.route?.fields['Meta Keywords']?.value;
  let description = sitecoreContext?.route?.fields['Meta Description']?.value;
  let canonical = sitecoreContext?.route?.fields['Canonical Url']?.value?.href;
  let canonicalLink = RemoveTrailingSlashes(
    canonical
      ? sitecoreContext?.custom?.targetHostName + canonical.substring(1)
      : sitecoreContext?.custom?.targetHostName
  );
  var robotsNoIndex = sitecoreContext?.route?.fields['No Index']?.value ? 'NOINDEX' : '';
  var robotsNoFollow = sitecoreContext?.route?.fields['No Follow']?.value ? 'NOFOLLOW' : '';
  let ogImage = sitecoreContext?.route?.fields['OG Image']?.value.src;
  var robotsSeparator =
    sitecoreContext?.route?.fields['No Index']?.value &&
    sitecoreContext?.route?.fields['No Follow']?.value
      ? ','
      : '';
  var robotsValue = robotsNoIndex + robotsSeparator + robotsNoFollow;

  return (
    <>
      <Helmet>
        {pageHeadInjection ? parser.parse(pageHeadInjection) : null}
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {robotsValue && <meta name="robots" content={robotsValue} />}
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
      </Helmet>
      <InnerHTML html={pageFooterInjection} />
    </>
  );
};

export default withSitecoreContext()(PageProperties);

import { buildSearchEngine, SearchEngine } from '@coveo/headless';
import config from '../../../temp/config';

class HeadlessEngine {
  engine: SearchEngine;
  constructor(queryPipeline?: string, searchHub?: string) {
    if (queryPipeline === '') {
      queryPipeline = config.coveoQueryPipeline;
    }
    if (searchHub === '') {
      searchHub = config.coveoSearchHub;
    }
    this.engine = buildSearchEngine({
      configuration: {
        accessToken: config.coveoAccessToken,
        organizationId: config.coveoOrganizationId,
        platformUrl: config.coveoPlatformUrl,
        search: { pipeline: queryPipeline, searchHub: searchHub },
      },
    });
  }
}

export default HeadlessEngine;

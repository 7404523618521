import React from 'react';
import { SearchBox as HeadlessSearchBox } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';

interface SearchBoxProps {
  controller: HeadlessSearchBox;
  resultsPage?: string;
  placeholderText?: string;
  cancelText?: string;
}

export const SearchBox: FunctionComponent<SearchBoxProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);
  const [navigateSearch, setNavigateSearch] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(
    () =>
      controller.subscribe(() => {
        setState(controller.state);
        setNavigateSearch(false);
      }),
    [controller]
  );

  function navigateToSearch() {
    if (props.resultsPage !== undefined && props.resultsPage === location.pathname) {
      setNavigateSearch(true);
      if (typeof window !== 'undefined') {
        window.location.hash = '#q=' + controller.state.value;
      }
    } else if (props.resultsPage !== undefined) {
      setNavigateSearch(true);
      if (typeof window !== 'undefined') {
        window.location.href = props.resultsPage + '#q=' + controller.state.value;
      }
    } else {
      controller.submit();
    }
  }

  return (
    <div className="search-field-wrapper">
      <div className="search-field-border">
        <div className="search-field-frame">
          <input
            className="headless-search-field-input"
            value={state.value}
            onChange={(e) => controller.updateText(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && navigateToSearch()}
            placeholder={props.placeholderText}
          />
          <div className="site-search-reset-button">
            {state.value !== '' ? (
              <input
                type="reset"
                value="reset"
                onTouchStart={(e) => {
                  setIsTouch(true);
                  controller.clear();
                }}
                onClick={(e) => {
                  if (isTouch === false) controller.clear();
                }}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="site-search-search-button">
            <input
              type="submit"
              value="Search"
              onTouchStart={(e) => {
                setIsTouch(true);
                navigateToSearch();
              }}
              onClick={(e) => {
                if (isTouch === false) navigateToSearch();
              }}
            />
          </div>
        </div>
      </div>
      <div className="cancel-search">{props?.cancelText}</div>
    </div>
  );
};

import React from 'react';
import { ResultList as HeadlessResultList, SearchEngine } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';

interface ResultListProps {
  controller: HeadlessResultList;
  hostName: string;
  engine: SearchEngine;
  noResults: any;
}

export const ResultList: FunctionComponent<ResultListProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  if (!state?.results.length) {
    return <InnerHTML html={props.noResults} />;
  }

  return (
    <div className="result-list">
      {state.results.map((result: any) => (
        <>
          {React.Children.map(props.children, (child: any) => {
            return React.cloneElement(child, {
              controller: controller,
              hostName: props.hostName,
              engine: props.engine,
              result: result,
            });
          })}
        </>
      ))}
    </div>
  );
};

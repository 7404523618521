import React from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Container, Grid } from '@material-ui/core';
import './index.scss';

export type SnowDragonComponentProps = {
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
};

const OneColumnContentRowFullWidth = (props: SnowDragonComponentProps): JSX.Element => {
  return (
    <>
      <Container className="container-full-width" maxWidth={false} disableGutters>
        <Grid container className="one-column-content-row content-row full-width">
          <Grid item xs={12}>
            <div>
              <Placeholder name="column_one" rendering={props.rendering} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OneColumnContentRowFullWidth;

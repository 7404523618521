import React, { useState, useEffect } from 'react';
import './index.scss';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

interface SiteMapProps {
  displayName: string;
  url: Field<string>;
  name: Field<string>;
  fields: {
    'No Index': Field<boolean>;
    'Exclude From Navigation': Field<boolean>;
    'Navigation Title': Field<string>;
    'Meta Description': Field<string>;
  };
}

type ConsultantProps = {
  fields: {
    items: [SiteMapProps];
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const SiteMapLinkListing = ({ sitecoreContext, fields }: ConsultantProps): JSX.Element => {
  let pathname = typeof window !== 'undefined' ? window.location.href : '';

  const [consultants, setConsultants] = useState<SiteMapProps[]>([]);
  const [numConsultants, setNumConsultants] = useState(consultants.length);

  // need to fill the consultants array.
  const fetchData = async () => {
    let consultantsArray = fields?.items.filter((item) => {
      return (
        item?.fields?.['No Index']?.value === false &&
        item?.fields?.['Exclude From Navigation']?.value === false &&
        item?.name.value !== 'Data' &&
        item?.fields?.['Navigation Title'].value !== 'Consultant Details Test' &&
        item?.fields?.['Navigation Title'].value !== 'Consultant Profile'
      );
    });
    sortAlphabetically(consultantsArray);
    setConsultants(consultantsArray);
  };

  useEffect(() => {
    if (numConsultants !== consultants.length) {
      fetchData();
      setNumConsultants(consultants.length);
    }
  }, [consultants]);
  useEffect(() => {
    fetchData();
    setNumConsultants(consultants.length);
  }, []);

  // function to cut off sitemap from location.
  const urlSlice = (pathname: string) => {
    return pathname.replace('/sitemap', '');
  };
  const sortAlphabetically = (consultArray: SiteMapProps[]) => {
    return consultArray.sort((a, b) => a?.displayName.localeCompare(b?.displayName));
  };

  if (fields && numConsultants > 0) {
    return (
      <>
        <p className="consultant-sitemap-header">
          <a href={`${urlSlice(pathname)}/consultants`}>
            <strong>Consultants</strong>
          </a>
        </p>
        <ul className="consultant-sitemap-list">
          {consultants.map((item: SiteMapProps, index: number) => {
            return (
              <li className="consultant-item" key={index}>
                <a
                  href={`${urlSlice(pathname) + item?.url}`}
                  title={item?.fields?.['Meta Description'].value}
                >
                  {item?.fields?.['Navigation Title'].value}
                </a>
              </li>
            );
          })}
        </ul>
      </>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

// this will be for the export
export default withSitecoreContext()(SiteMapLinkListing);

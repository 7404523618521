import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomFieldValidationErrors: React.FunctionComponent<any> = (props) => {
  if (props.fieldValidationErrorsComponent) {
    const CustomError = props.fieldValidationErrorsComponent;

    // strip the errors component from the custom component props
    // (prevents infinite loop rendering if someone reuses this component as a custom error component)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fieldValidationErrorsComponent, ...errorsComponentProps } = props;

    return <CustomError {...errorsComponentProps} />;
  }

  const { errors } = props;
  const { t, i18n } = useTranslation();

  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className="invalid">
      {errors.map(
        (
          error:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <p key={index}>{error?.toString().replace(/is required?./, t('is-required-text'))}</p>
        )
      )}
    </div>
  );
};

export default CustomFieldValidationErrors;

// Generate Unique IDs for elements
function gen4() {
  return Math.random().toString(16).slice(-4);
}

function simpleUniqueId(prefix: any) {
  return (prefix || '').concat(
    [gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4()].join('')
  );
}

function RemoveTrailingSlashes(url: string) {
  let trimmedUrl = url;
  if (trimmedUrl.endsWith('/')) {
    trimmedUrl = trimmedUrl.slice(0, trimmedUrl.length - 1);
    trimmedUrl = RemoveTrailingSlashes(trimmedUrl);
  }

  return trimmedUrl;
}

// function to grab sxa_site if it exists and returns a true or false.
function isCookiesAllowed(): boolean {
  return document.cookie.length !== 0;
}

export { simpleUniqueId, isCookiesAllowed, RemoveTrailingSlashes };

import React from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import { Pagination } from '@material-ui/lab';
import InnerHTML from 'dangerously-set-html-content';
import './index.scss';

interface DataSource {
  'Results Root Item': {
    value: string;
  };
  'Root of Site': {
    value: string;
  };
  'Error Message': {
    value: string;
  };
  'No Results Message': {
    value: string;
  };
  'Loading Message': {
    value: string;
  };
}
interface SearchResult {
  id: string;
  path: string;
  name: string;
  title: string;
  description: string;
  link: string;
  canonical: string;
}
interface mySearchResults {
  results: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: number;
      endCursor: number;
    };
    items: [SearchResult];
  };
}
interface SearchVariables {
  userQuery: string;
}

interface SearchQueryVariables {
  name: string;
  options: {
    variables: {
      userQuery: string;
      rootItem: string;
      startIndex: string;
    };
  };
}

type SearchResultsProps = {
  rendering: {
    fields: {
      data: {
        datasource: DataSource;
      };
    };
  };

  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    itemPath: string;
    custom: {
      targetHostName: string;
    };
  };

  searchResultsQuery: {
    searchResults: mySearchResults;
    variables: SearchVariables;
    loading: boolean;
    error: {
      message: string;
      stack: string;
    };
  };

  fields: DataSource;
};

const SearchResults = (props: SearchResultsProps): JSX.Element => {
  // Async loading and error handling
  // Remember to never return null from a JSS component when loading,
  // this will break Experience Editor.
  const { fields, sitecoreContext } = props;

  if (!props.searchResultsQuery || !fields) {
    if (sitecoreContext.pageEditing) {
      return <p>Please setup the Datasource.</p>;
    } else {
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );
    }
  }

  // Query results load in using the name of their root field (see query.graphql)
  const { searchResults, loading, variables, error } = props.searchResultsQuery;

  if (loading || typeof window === 'undefined') {
    return <InnerHTML html={fields['Loading Message'].value} />;
  }

  // TODO - Update this to allow the error message results datasource to reference an RTE and log the error
  if (error) {
    if (sitecoreContext.pageEditing) {
      return (
        <React.Fragment>
          <br />
          <br />
          <br />

          <h3>{error.message}</h3>
          <p>{error.stack}</p>
          <br />
          <br />
          <br />
        </React.Fragment>
      );
    } else {
      return (
        <>
          <InnerHTML html={fields['Error Message'].value} />
          <React.Fragment>
            <div style={{ display: 'none' }}>
              <h3>{error.message}</h3>
              <p>{error.stack}</p>
            </div>
            <br />
            <br />
            <br />
          </React.Fragment>
        </>
      );
    }
  }

  // TODO - Update this to allow the search results datasource to reference and RTE for the
  //       no search results message.
  if (searchResults.results.totalCount === 0) {
    return <InnerHTML html={fields['No Results Message'].value} />;
  }

  let pages = 0;
  let pageSize = 10;
  let currentPage = 1;
  if (searchResults.results.totalCount > currentPage) {
    pages = Math.ceil(searchResults.results.totalCount / pageSize);
    currentPage = Math.ceil(searchResults.results.pageInfo.endCursor / pageSize);
  } else {
    pages = 1;
  }
  let fullpath = sitecoreContext.custom.targetHostName;
  fullpath = fullpath.replace('http://', 'https://');
  let pathParts = fullpath.split('/');

  let hostname = fullpath.substring(0, fullpath.search(pathParts[pathParts.length - 1]));
  let path = '/' + pathParts[pathParts.length - 1];

  // TODO modify this implementation to an in-page load rather than a reload
  const handlePageChange = (event: any, value: any) => {
    if (typeof window !== 'undefined') {
      let query =
        fullpath +
        '?q=' +
        props.searchResultsQuery.variables.userQuery +
        '&si=' +
        (value - 1) * pageSize;
      window.open(query, '_self');
      event.preventDefault();
    }
  };

  // Update links to relative paths
  let basePathLength = fields['Root of Site']?.value?.length;
  function trimSiteRootFromItemPath(item: SearchResult, index: number, array: [SearchResult]) {
    array[index].link = item.link.substring(basePathLength + 1).replace(/\s+/g, '-');
  }
  searchResults.results.items.forEach(trimSiteRootFromItemPath, 0);

  return (
    <div className="search-results">
      {searchResults && (
        <div>
          <span className="search-summary">
            Showing results{' '}
            <strong>
              {' '}
              {searchResults.results.pageInfo.startCursor}-
              {searchResults.results.pageInfo.endCursor}
            </strong>{' '}
            of <strong>{searchResults.results.totalCount}</strong>
            &nbsp;results{' '}
            {variables.userQuery.length > 0 ? 'for "' + variables.userQuery + '"' : null}
          </span>
          <hr />
          {searchResults.results.items.map((result: SearchResult) => (
            <div key={result.id} className="search-standard-result">
              <div>
                <a href={result.link} className="search-result-name">
                  {result.title}
                </a>
                <p className="search-result-description"> {result.description}</p>
              </div>
              <a className="search-result-link" href={result.link}>
                {hostname + result.link}
              </a>
            </div>
          ))}

          <div className="search-result-pagination">
            <Pagination
              count={pages}
              page={currentPage}
              color="secondary"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

function getUserQuery() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let query = params?.get('q') || '';
  return query;
}

function getStartItem() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let start = params?.get('si') || '0';
  return start;
}

function getRootItem(props: any) {
  let rootItemPath = props.fields['Results Root Item']?.value;
  if (rootItemPath) {
    return rootItemPath;
  } else {
    return '/sitecore/content/UPMC/Global';
  }
}
const SearchResultsQuery = gqlLoader('./query.graphql');
const MakeQuery = (props: any) => {
  const WrappedSearchResults = GraphQLData(SearchResultsQuery, {
    name: 'searchResultsQuery',
    options: {
      variables: {
        userQuery: getUserQuery(),
        rootItem: getRootItem(props),
        startIndex: getStartItem(),
      },
      fetchPolicy: 'no-cache',
    },
  })(SearchResults);
  return <WrappedSearchResults {...props} />;
};

export default MakeQuery;

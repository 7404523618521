import React from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '@material-ui/core';
import './index.scss';
/**
 * TODO: Move to global folder
 * TODO: Remove SnowDragon Ref in title
 * Shared SnowDragon component props
 */
export type SnowDragonComponentProps = {
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
};

const ThreeColumnContentRow = (props: SnowDragonComponentProps): JSX.Element => {
  return (
    <>
      <Grid
        container
        item
        alignItems="stretch"
        className="three-column-content-row content-row"
        xs={12}
      >
        <Grid item xs={12} md={4}>
          <div>
            <Placeholder name="column_one" rendering={props.rendering} />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div>
            <Placeholder name="column_two" rendering={props.rendering} />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div>
            <Placeholder name="column_three" rendering={props.rendering} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ThreeColumnContentRow;

import React from 'react';
import { ResultList as HeadlessResultList, SearchEngine } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import { ResultLink } from '../resultlink';
import { GetSearchUrl } from '../utils';

interface ResultListProps {
  controller?: HeadlessResultList;
  hostName?: string;
  engine: SearchEngine;
  result?: any;
}

export const DefaultResult: FunctionComponent<ResultListProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller?.state);

  useEffect(() => controller?.subscribe(() => setState(controller?.state)), [controller]);

  function getUrl(props: any) {
    return GetSearchUrl(props.result, props.hostName);
  }

  return (
    <article key={props.result.uniqueId} className="result-item">
      <span className="search-result-name">
        <ResultLink
          result={props.result}
          hostName={props.hostName}
          useUrlAsLinkText={false}
          engine={props.engine}
          getUrl={() => getUrl(props)}
        >
          {props.result.raw.pagez32xbodyz32xtitle
            ? props.result.raw.pagez32xbodyz32xtitle
            : props.result.title}
        </ResultLink>
      </span>
      <p className="search-result-description">{props.result.raw.metaz32xdescription}</p>
      <span className="search-result-link">
        <ResultLink
          result={props.result}
          hostName={props.hostName}
          useUrlAsLinkText={true}
          engine={props.engine}
          getUrl={() => getUrl(props)}
        ></ResultLink>
      </span>
    </article>
  );
};

import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';

import './index.scss';
// THIS COMPONENT IS DEPRECATED AND IS NO LONG IN USE WITHIN OUR SOLUTION 9/20/2023
const SearchResultsQuery = gqlLoader('./query.graphql');

interface DataSource {
  'Results Root Item': {
    value: string;
  };
  'Root of Site': {
    value: string;
  };
}

interface JobPostingResults {
  category: string;
  title: string;
  id: string;
  type: string;
  hoursOrShifts: string;
  department: string;
  reportsTo: string;
  link: string;
  item: {
    positionLocation: {
      targetItem: {
        locationName: {
          value: string;
        };
      };
    };
  };
}

interface myjobPostingResults {
  results: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: number;
      endCursor: number;
    };
    items: [JobPostingResults];
  };
}

type JobPostingListingProps = {
  rendering: {
    fields: {
      data: {
        datasource: DataSource;
      };
    };
  };
  jobPostingResultsQuery: {
    jobPostingResults: myjobPostingResults;
    loading: boolean;
    error: string;
  };
  fields: DataSource;
};

const isValidJobPosting = (jobPosting: JobPostingResults) => {
  return jobPosting.category ? true : false;
};

// JOB POST LAYER
const JobPostingSummary = (jobPosting: JobPostingResults): JSX.Element => {
  if (!isValidJobPosting(jobPosting)) return <></>;

  return (
    <Grid item xs={12} className="job-posting-summary">
      {/* Job Title */}
      <h2 className="title">{jobPosting.title}</h2>
      {jobPosting.department ? <span className="department">{jobPosting.department}</span> : <></>}
      {/* Flex container for details container */}
      <div className="details-wrapper">
        <div className="details-text-wrapper">
          {/* Location */}
          <div className="details-text-location-wrapper">
            {jobPosting.item.positionLocation?.targetItem?.locationName?.value ? (
              <p>{jobPosting.item.positionLocation.targetItem.locationName.value}</p>
            ) : (
              <></>
            )}
          </div>
          {/* Shift */}
          <div className="details-text-shift-wrapper">
            {jobPosting.type ? <p>{jobPosting.type}</p> : <></>}
            {jobPosting.hoursOrShifts ? <p>{jobPosting.hoursOrShifts}</p> : <></>}
          </div>
        </div>
        {/* Button */}
        <div>
          <a href={jobPosting.link}>
            <Button color="secondary" variant="contained">
              Position Details
            </Button>
          </a>
        </div>
      </div>
    </Grid>
  );
};

// JOB LIST LAYER
const JobPostingListing = ({
  jobPostingResultsQuery,
  fields,
}: JobPostingListingProps): JSX.Element => {
  const { jobPostingResults, loading, error } = jobPostingResultsQuery;

  // Validation & Error Handling
  if (!jobPostingResultsQuery) {
    return (
      <>
        <br />
        <br />
        <br />
        Invalid datasource for SearchResults.
        <br />
        <br />
        <br />
      </>
    );
  }

  if (loading) {
    return (
      <>
        <br />
        <br />
        <br />
        <span className="loading-message">Loading Results...</span>
        <br />
        <br />
        <br />
      </>
    );
  }

  if (error) {
    return (
      <>
        <br />
        <br />
        <br />
        <span className="error-message">{error}</span>
        <br />
        <br />
        <br />
      </>
    );
  }

  if (jobPostingResults.results.totalCount === 0) {
    return (
      <>
        <br />
        <br />
        <br />
        <span className="no-results-message">No results found.</span>
        <br />
        <br />
        <br />
      </>
    );
  }

  let basePathLength = fields['Root of Site']?.value?.length;
  function clearLinks(item: JobPostingResults, index: number, array: [JobPostingResults]) {
    array[index].link = item.link.substring(basePathLength);
  }
  function trimLinks(item: JobPostingResults, index: number, array: [JobPostingResults]) {
    let trimmedPath = array[index].link.split('/');
    trimmedPath = trimmedPath.slice(0, trimmedPath.length - 2);
    array[index].link = trimmedPath.join('/').replace(/\s+/g, '-');
  }
  jobPostingResults.results.items.forEach(clearLinks, 0);
  jobPostingResults.results.items.forEach(trimLinks);

  return (
    <div className="job-listing">
      {jobPostingResults && (
        <Grid container alignItems="stretch" xs={12}>
          {jobPostingResults.results.items.map((jobPosting: JobPostingResults) =>
            JobPostingSummary(jobPosting)
          )}
        </Grid>
      )}
    </div>
  );
};

let uri = typeof window !== 'undefined' ? window.location.search : '';
let params = new URLSearchParams(uri.substring(1));
let query = params?.get('q') || '';
let start = params?.get('si') || '0';

const path = typeof window !== 'undefined' ? window.location.pathname : 'Careers';

export default GraphQLData(SearchResultsQuery, {
  name: 'jobPostingResultsQuery',
  options: {
    variables: {
      userQuery: query,
      rootItem: `/sitecore/content/UPMC/Italy/Home/${path}`,
      startIndex: start,
    },
    fetchPolicy: 'no-cache',
  },
})(JobPostingListing);

import React, { useState, useEffect } from 'react';
import {
  Field,
  LinkFieldValue,
  RichText,
  SitecoreContext,
  withSitecoreContext,
  Link,
} from '@sitecore-jss/sitecore-jss-react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Helmet from 'react-helmet';
import { useLocation, useHistory } from 'react-router-dom';

import './index.scss';

interface AlertProps {
  displayName: string;
  id: string;
  name: string;
  url: string;
  fields: {
    uid: Field<string>;
    Heading: Field<string>;
    'Alert Enabled': Field<boolean>;
    'Alert Title': Field<string>;
    'Alert Message': Field<string>;
    'Alert Link': Field<LinkFieldValue>;
    Priority: Field<string>;
    'Display On Subpages': Field<boolean>;
    'Display On Page': {
      url: string;
      name: string;
    };
  };
}

type EmergencyAlertProps = {
  fields: {
    items: [AlertProps];
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};
const EmergencyAlert = ({ fields, sitecoreContext }: EmergencyAlertProps): JSX.Element => {
  const cssDisabled = 'main {margin-top: -6px !important;}';
  const cssEnabled = 'main{margin-top: 140px;} @media (min-width:1280px){main{margin-top: 0;}}';
  let pathname = typeof window !== 'undefined' ? window.location.pathname : '';
  let pathHref = typeof window !== 'undefined' ? window.location.href : '';
  const [current, setCurrent] = useState(0);
  const [alertRules, setAlertRules] = useState<AlertProps[]>([]);
  const [numAlertsActive, setNumAlertsActive] = useState(alertRules ? alertRules.length : 0);
  const fetchData = async () => {
    var alertRules = fields?.items?.filter((item) => {
      return item?.fields?.['Alert Enabled']?.value === true && UrlCheck(item) && getCookie(item);
    });
    if (alertRules === null) {
      alertRules = [];
    }
    setAlertRules(alertRules);
  };
  useEffect(() => {
    if (numAlertsActive !== (alertRules ? alertRules.length : 0)) {
      fetchData();
      setNumAlertsActive(alertRules ? alertRules.length : 0);
    }
  }, [alertRules]);

  // This runs on route change to check if cookies or domains have changed
  const location = useLocation();
  useEffect(() => {
    fetchData();
  }, [location.key]);

  // This is run only once on first load.
  useEffect(() => {
    fetchData();
    setNumAlertsActive(alertRules ? alertRules.length : 0);
  }, []);

  const nextSlide = () => {
    setCurrent(current === numAlertsActive - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? numAlertsActive - 1 : current - 1);
  };

  const handleCurrentChange = (step: number) => {
    setCurrent(step);
  };

  function endsWithAny(str: string, suffixes: string[]): boolean {
    return suffixes.some((suffix) => str.endsWith(suffix));
  }

  function checkLanguageURL(url: string): boolean {
    const languageSuffixes: string[] = ['/en', '/it'];
    return endsWithAny(url, languageSuffixes);
  }

  function UrlCheck(item: AlertProps) {
    const stripTrailingSlash = (str: string) => {
      return str.endsWith('/') ? str.slice(0, -1) : str;
    };
    if (
      stripTrailingSlash(pathname).indexOf(item?.fields?.['Display On Page']?.url) > -1 &&
      item?.fields?.['Display On Subpages']?.value === true
    ) {
      return true;
    } else if (
      pathHref.substring(pathHref.lastIndexOf('/')) === item?.fields?.['Display On Page']?.url
    ) {
      return true;
    } else if (
      checkLanguageURL(pathHref) === true &&
      item?.fields?.['Display On Page']?.url === '/'
    ) {
      return true;
    } else {
      return false;
    }
  }

  function getCookie(item: AlertProps) {
    var nameEQ = 'upmcAlert' + item.id + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return false;
      }
    }
    return true;
  }

  function setAlertCookie(id: string) {
    if (typeof window !== 'undefined') {
      document.cookie = 'upmcAlert' + id + '=1';
    }
  }

  const removeItem = (index: number) => {
    if (numAlertsActive > 1) {
      setAlertRules(alertRules.filter((o, i) => index !== i));
    } else {
      setAlertRules([]);
      window.location.reload();
      // When all alerts are closed refresh the page to clear desktop and mobile versions of the component to correct styling in mobile
    }
  };

  function hideThisAlert(item: AlertProps, index: number) {
    var id = item.id;
    setAlertCookie(id);
    removeItem(index);
    setNumAlertsActive(alertRules.length);
  }

  if (fields && sitecoreContext.pageEditing) {
    return (
      <>
        <div>
          <span>Please edit the Emergency Alert in Content Editor.</span>
        </div>
      </>
    );
  } else if (fields && numAlertsActive >= 1) {
    return (
      <>
        <Helmet>
          <style>{cssEnabled}</style>;
        </Helmet>
        <div
          key={location.key}
          className={'emergency-alert-wrapper ' + alertRules?.[current]?.fields?.Priority?.value}
        >
          {numAlertsActive >= 2 && (
            <div className={'emergency-alert-carousel '}>
              <IconButton
                color="primary"
                className="previous-alert"
                aria-label="view previous slide"
                onClick={prevSlide}
              >
                <KeyboardArrowLeft className="slide-back" />
              </IconButton>
              <IconButton
                color="primary"
                className="next-alert"
                aria-label="view next slide"
                onClick={nextSlide}
              >
                <KeyboardArrowRight className="slide-forward" />
              </IconButton>
            </div>
          )}
          {alertRules.map((item: AlertProps, index: number) => {
            if (getCookie(item)) {
              return (
                <div className={index === current ? 'item active' : 'item'} key={index}>
                  <div className={'emergency-alert ' + item?.fields?.Priority?.value}>
                    <div className="alert-icon">
                      <ErrorOutlineIcon />
                    </div>
                    <div className="alert-title">
                      <p>{item?.fields?.['Alert Title']?.value}</p>
                    </div>
                    <div className="alert-details">
                      <div className="alert-content">
                        <div className="alert-message">
                          <RichText
                            className="description"
                            field={item?.fields?.['Alert Message']}
                          />
                        </div>
                        <div className="alert-link">
                          <Link field={item?.fields?.['Alert Link']} />
                        </div>
                      </div>
                    </div>
                    <div className="alert-close">
                      <IconButton
                        edge="start"
                        className="emergency-alert-close"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => hideThisAlert(item, index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </>
    );
  } else if (fields && numAlertsActive < 1) {
    <>
      <Helmet>
        <style>{cssDisabled}</style>;
      </Helmet>
    </>;
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};
export default withSitecoreContext()(EmergencyAlert);

import React from 'react';
import InnerHTML from 'dangerously-set-html-content';

import './index.scss';

interface Props {}

export default function RawHtml(props: any) {
  return (
    <div className="raw-html">
      <InnerHTML html={props.field.model.rawHtml} />
    </div>
  );
}

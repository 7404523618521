import React, { FunctionComponent, useEffect, useState } from 'react';
import { buildCriterionExpression, Sort as HeadlessSort, SortCriterion } from '@coveo/headless';

interface SortProps {
  controller: HeadlessSort;
  criteria: [string, SortCriterion][];
}

export const Sort: FunctionComponent<SortProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  const getCriterionFromName = (name: string) =>
    props.criteria.filter(([criterionName]) => criterionName === name);

  const getCurrentCriterion = () =>
    props.criteria.filter(
      ([, criterion]) => state.sortCriteria === buildCriterionExpression(criterion)
    );

  return (
    <div className="sort">
      <p>Sort by:</p>
      <select
        value={getCurrentCriterion()[0][0]}
        onChange={(e) => controller.sortBy(getCriterionFromName(e.target.value)[0][1])}
      >
        {props.criteria.map(([criterionName]) => (
          <option key={criterionName} value={criterionName}>
            {criterionName}
          </option>
        ))}
      </select>
    </div>
  );
};

import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

const SkipToContent = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  return (
    <div className="skiptocontent">
      <a href="#main-content"> {t('skip-to-content-text')}</a>
    </div>
  );
};

export default SkipToContent;

import React from 'react';
import {
  LinkFieldValue,
  ImageField,
  Field,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { ResponsiveImage, ResponsiveImageProps } from '../ResponsiveImage';
import './index.scss';

type PageHeroProps = {
  fields: {
    'Desktop Image': ImageField;
    'Mobile Image': ImageField;
    'Tablet Image': ImageField;
    Link: Field<LinkFieldValue>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const PageHero = ({ fields, sitecoreContext }: PageHeroProps): JSX.Element => {
  if (fields) {
    let imageObj: ResponsiveImageProps = {
      responsiveFields: {
        MobileImageSrc: fields['Mobile Image'],
        TabletImageSrc: fields['Tablet Image'],
        DesktopImageSrc: fields['Desktop Image'],
      },
      sitecoreContext: sitecoreContext,
    };
    var ctaLink = fields.Link?.value;
    return (
      <div className="pagehero">
        {sitecoreContext.pageEditing && <p>PageHero Component Edit Mode</p>}
        {ctaLink?.href ? (
          <a href={ctaLink?.href} target={ctaLink?.target}>
            <ResponsiveImage
              responsiveFields={imageObj.responsiveFields}
              sitecoreContext={imageObj.sitecoreContext}
            />
          </a>
        ) : (
          <ResponsiveImage
            responsiveFields={imageObj.responsiveFields}
            sitecoreContext={imageObj.sitecoreContext}
          />
        )}
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(PageHero);

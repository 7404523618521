import React from 'react';
import { Placeholder, RouteData, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { Grid, Container } from '@material-ui/core';
import './index.scss';

type RenderingProps = {
  rendering: RouteData | ComponentRendering;
};

const SectionWithMargin = ({ rendering }: RenderingProps) => {
  return (
    <Container maxWidth="lg" className="section-full-width">
      <Grid direction="row">
        <Grid item>
          <Placeholder name="section_with_margin" rendering={rendering} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SectionWithMargin;

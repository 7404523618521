import React, { useContext } from 'react';
import {
  Field,
  LinkFieldValue,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import GeneralContext from '../../../context/general';
import './index.scss';
import { buildSearchBox } from '@coveo/headless';
import { SearchBox } from '../lib/searchbox';

type SearchBoxProps = {
  fields: {
    ResultsPage: Field<LinkFieldValue>;
    PlaceholderText: Field<string>;
    CancelText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: any };
};

const CoveoSearchBox = ({ fields, sitecoreContext }: SearchBoxProps): JSX.Element => {
  const headlessEngine = useContext(GeneralContext).generalSearchEngine;
  const searchBox = buildSearchBox(headlessEngine);

  return (
    <div className="search-box-container">
      {sitecoreContext.pageEditing && (
        <>
          <p>SearchBox Component Edit Mode</p>
        </>
      )}
      <SearchBox
        controller={searchBox}
        resultsPage={fields.ResultsPage?.value?.href}
        placeholderText={fields?.PlaceholderText?.value}
        cancelText={fields?.CancelText?.value}
      />
    </div>
  );
};

export default withSitecoreContext()(CoveoSearchBox);

import React from 'react';
import { DidYouMean as HeadlessDidYouMean } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DidYouMeanProps {
  controller: HeadlessDidYouMean;
}

export const DidYouMean: FunctionComponent<DidYouMeanProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);
  const { t, i18n } = useTranslation();

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  if (!state.hasQueryCorrection) {
    return <></>;
  }

  if (state.wasAutomaticallyCorrected) {
    return (
      <div className="did-you-mean-text">
        {t('did-you-mean-no-results-for')}{' '}
        <b>{state.queryCorrection.wordCorrections[0].originalWord}</b>.{' '}
        {t('did-you-mean-automatic-correction')} <b>{state.wasCorrectedTo}</b>.
      </div>
    );
  }

  return (
    <>
      <span>{t('did-you-mean-text')} </span>
      <a onClick={() => controller.applyCorrection()}>{state.queryCorrection.correctedQuery}</a>
    </>
  );
};

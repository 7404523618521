import React, { useContext, useEffect } from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './index.scss';
import { ICustomContext } from '../../model/ICustomContext';

type LangugageSelectorEditorProps = {
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    custom: ICustomContext;
    language: string;
    itemId: string;
  };
};

const LangugageSelector = ({ sitecoreContext }: LangugageSelectorEditorProps): JSX.Element => {
  const [languages, setLanguages] = React.useState<Record<string, string>>({});
  const [language, setLanguage] = React.useState<string>('');

  const context = React.createContext(sitecoreContext);
  const currentContext = useContext(context);

  useEffect(() => {
    const languageList = sitecoreContext?.custom?.siteConfiguration?.siteLanguages || [];
    const newLanguages: Record<string, string> = {};

    languageList.forEach((language) => {
      const abbreviation = language.abbreviation;
      const label = language.label;
      newLanguages[abbreviation] = label;
    });

    setLanguages(newLanguages);

    const langCode = sitecoreContext?.language;
    if (langCode) {
      setLanguage(newLanguages[langCode]);
    }
  }, [sitecoreContext]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedLanguage = typeof event.target.value === 'string' ? event.target.value : '';
    setLanguage(selectedLanguage);
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  function getMenuLinks() {
    let links = [];
    for (var key in languages) {
      if (key !== null) {
        let link = (
          <MenuItem value={languages[key]}>
            <a key={key} href={currentContext?.custom?.urlPathTranslations[key]}>
              {languages[key]}
            </a>
          </MenuItem>
        );

        links.push(link);
      }
    }
    return links;
  }

  return (
    <div className="language-selector">
      <img alt="globe-icon" src="/-/media/Project/UPMC/Italy/Icons/iconglobe.svg" />
      <Select
        id="languageSelect"
        aria-label="language selector"
        displayEmpty
        value={language}
        onChange={handleChange}
      >
        {getMenuLinks().map((link) => link)}
      </Select>
    </div>
  );
};

export default withSitecoreContext()(LangugageSelector);

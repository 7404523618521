import React, { useState } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import MenuIcon from '@material-ui/icons/Menu';
import { ICustomContext } from '../../../model/ICustomContext';
import CloseIcon from '@material-ui/icons/Close';
import DrillDownMenu from '../DrilldownMenu';
import './index.scss';

type MobileNavigationProps = {
  fields: {
    data: any;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: ICustomContext; route: any };
};

const MobileNavigation = ({ sitecoreContext }: MobileNavigationProps): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState(false);

  function toggleDrawer(open: boolean, event: any) {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  }

  return (
    <div className="MobileNavigation">
      <Drawer
        disableEnforceFocus={true}
        anchor="right"
        variant="persistent"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        hideBackdrop={true}
        className="mobile-drawer"
      >
        <div role="presentation" className="mobile-drawer inner-drawer">
          <DrillDownMenu
            dataSource={sitecoreContext.custom?.siteHierarchy?.homeNode}
            toggleDrawer={setOpenDrawer}
            currentPageId={'{' + sitecoreContext?.route?.itemId + '}'}
          ></DrillDownMenu>
        </div>
      </Drawer>
      <IconButton
        edge="start"
        className="mobile-menu-button"
        color="inherit"
        aria-label="open drawer"
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        {openDrawer ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
    </div>
  );
};

export default withSitecoreContext()(MobileNavigation);

import React from 'react';

export interface ISpecialty {
  displayName: string;
}

export const SpecialtyTitle = ({ fields }: any): JSX.Element => {
  return (
    <ul className="specialty-container">
      {fields.Specialties?.length > 0 &&
        fields.Specialties?.map((specialty: ISpecialty, i: any) => (
          <li key={i} className="physician-specialty-subheading">
            {specialty.displayName}
          </li>
        ))}
      {fields.Specialties?.length === 0 && fields.Specialty && (
        <li className="physician-specialty-subheading">{fields.Specialty.value}</li>
      )}
    </ul>
  );
};

export default SpecialtyTitle;

import React from 'react';
import {
  Field,
  Image,
  ImageField,
  LinkFieldValue,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

export type LogoProps = {
  fields: {
    'Logo Image': ImageField;
    Link: Field<LinkFieldValue>;
  };
  params: {
    Direction: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const Logo = ({ fields, sitecoreContext, params }: LogoProps): JSX.Element => {
  if (fields) {
    return (
      <div className={'logo ' + params?.Direction}>
        {
          <>
            <a
              href={fields.Link?.value?.href}
              target={fields.Link?.value?.target}
              title={fields.Link?.value?.title}
              className="logoLink"
            >
              <Image className="logoImage" field={fields['Logo Image']?.value} />
            </a>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(Logo);

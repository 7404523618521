import React, { FunctionComponent, useEffect, useState } from 'react';
import { QuerySummary as HeadlessQuerySummary, QuerySummaryState } from '@coveo/headless';
import InnerHTML from 'dangerously-set-html-content';

interface QuerySummaryProps {
  controller: HeadlessQuerySummary;
  title?: string;
}

function renderNoResults() {
  return <></>;
}

function renderHasResults(state: QuerySummaryState, title?: string) {
  let formattedSummary: string = title || '';
  const durationInSeconds = state.durationInSeconds.toString();
  const query = '<span><strong>' + state.query.toString() + '</strong></span>';
  const total = '<span><strong>' + state.total.toString() + '</strong></span>';
  const range = '<span><strong>' + state.firstResult + '-' + state.lastResult + '</strong></span>';

  formattedSummary = formattedSummary
    .replace('%RANGE%', range)
    .replace('%TOTAL%', total)
    .replace('%QUERY%', query)
    .replace('%SECONDS%', durationInSeconds);
  return (
    <>
      <div className="query-summary">
        <InnerHTML html={formattedSummary} />
      </div>
    </>
  );
}

export const QuerySummary: FunctionComponent<QuerySummaryProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  if (!props.controller.state.hasResults) {
    return renderNoResults();
  }
  return renderHasResults(props.controller.state, props.title);
};

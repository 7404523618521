import React, { useState, useEffect } from 'react';
import { IconButton, List, ListItem, ListItemText } from '@material-ui/core';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IContentPageBase } from '../../../model/IContentPageBase';
import './index.scss';

type drilldownProps = {
  dataSource: IContentPageBase;
  toggleDrawer: any;
  currentPageId: string;
};

function findItemFromSiteHierarchy(source: IContentPageBase, id: string): IContentPageBase {
  var itemFound = null;
  if (source !== null && id !== null && source.id !== id) {
    if (source.id?.toLowerCase() === id?.toLowerCase()) {
      itemFound = source;
    } else {
      for (const child of source.children) {
        itemFound = findItemFromSiteHierarchy(child, id);
        if (itemFound && itemFound.id?.toLowerCase() === id?.toLowerCase()) {
          break;
        } else {
          itemFound = null;
        }
      }
    }
  }
  // If no match is found, fall back to the top node
  return itemFound !== null ? itemFound : source;
}

const DrillDownMenu = ({
  dataSource,
  toggleDrawer,
  currentPageId,
}: drilldownProps): JSX.Element => {
  const [parent, setParent] = useState<IContentPageBase>(
    findItemFromSiteHierarchy(dataSource, currentPageId)
  );
  const [initialized, init] = useState<Boolean>(false);

  useEffect(() => {
    var currentNode = findItemFromSiteHierarchy(dataSource, currentPageId);

    if (!initialized) {
      init(true);
      setParent(findItemFromSiteHierarchy(dataSource, currentPageId));
    }
    if (parent !== dataSource) {
      setParent(currentNode);
    }
  }, [currentPageId]);
  let isTopLevel = false;
  if (dataSource?.id === parent?.id) {
    isTopLevel = true;
  }

  function getNavigateIcon(item: IContentPageBase, parent: any) {
    var showIcon = item.children?.length > 0;
    for (let child of item.children) {
      if (!child.isNavExclude) {
        showIcon = true;
        break;
      }
      showIcon = false;
    }

    if (!item.isNavChildrenExclude && showIcon) {
      return (
        <IconButton
          className="arrow-icon-wrapper"
          onClick={() => {
            setParent(item);
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      );
    }
    return <></>;
  }
  function getParent() {
    var parentID = parent.parentID?.split('|');
    var itemFound = null;
    if (parentID?.length > 0) {
      itemFound = findItemFromSiteHierarchy(dataSource, parentID[0]);
    } else {
      itemFound = findItemFromSiteHierarchy(dataSource, parent.parentID);
    }
    return itemFound;
  }

  function getBackArrow() {
    var backParent = getParent();
    return (
      <>
        {isTopLevel ? null : (
          <div className="drilldown-header">
            <IconButton
              onClick={() => {
                var itemFound = getParent();
                setParent(itemFound);
              }}
            >
              <div className="mobile-back-navigation">
                <ArrowBackIosIcon />
                <span>{backParent?.navTitle}</span>
              </div>
            </IconButton>
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <div className="drilldown-container ">
        {getBackArrow()}
        <div className="drilldown-title">
          <a
            href={parent?.url}
            onClick={() => {
              toggleDrawer(false);
            }}
          >
            {parent?.navTitle}
          </a>
        </div>
        <div className="drilldown-body">
          <List>
            {parent?.children?.map((item: IContentPageBase) => {
              if (item.isNavExclude) {
                return <></>;
              }
              return (
                <>
                  <ListItem button key={item.navTitle}>
                    <a
                      href={item.url}
                      onClick={() => {
                        toggleDrawer(false);
                      }}
                    >
                      <ListItemText primary={item.navTitle} />
                    </a>
                    <>{getNavigateIcon(item, parent)}</>
                  </ListItem>
                </>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default DrillDownMenu;

import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';

type NewsPageSchemaProps = {
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    route: {
      fields: {
        Headline: Field<string>;
        Author: Field<string>;
        'Date Published': Field<string>;
        'Date Modified': Field<string>;
        'Schema Image': {
          value: {
            src: string;
          };
        };
      };
    };
  };
};

const NewsPageSchema = ({ sitecoreContext }: NewsPageSchemaProps): JSX.Element => {
  if (!sitecoreContext || sitecoreContext.pageEditing) {
    return <p>News Page Schema Included</p>;
  }
  let pathname = typeof window !== 'undefined' ? window.location.href : '';
  let headline = sitecoreContext?.route?.fields?.Headline?.value;
  let author = sitecoreContext?.route?.fields?.Author?.value;
  let datePublished = sitecoreContext?.route?.fields?.['Date Published']?.value;
  let dateModified = sitecoreContext?.route?.fields?.['Date Modified']?.value;
  let schemaImage = sitecoreContext?.route?.fields?.['Schema Image']?.value?.src;
  if (dateModified === '0001-01-01T00:00:00Z') {
    dateModified = '';
  }
  if (datePublished === '0001-01-01T00:00:00Z') {
    datePublished = '';
  }
  if (schemaImage === undefined) {
    schemaImage = '';
  }

  const News_Schema = JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'NewsArticle',
    headline: `${headline}`,
    image: `${schemaImage}`,
    datePublished: `${datePublished}`,
    dateModified: `${dateModified}`,
    author: {
      '@type': 'Person',
      name: `${author}`,
    },
    url: `${pathname}`,
  });
  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {News_Schema}
      </script>
    </Helmet>
  );
};
export default withSitecoreContext()(NewsPageSchema);

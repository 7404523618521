import React, { useContext, useEffect, useState } from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ICustomContext } from '../../model/ICustomContext';

import './index.scss';
import { IContentPageBase } from '../../model/IContentPageBase';
import { List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { Helmet } from 'react-helmet';

type BreadcrumbsProps = {
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    custom: ICustomContext;
    itemId: string;
    route: any;
  };
};

class breadcrumbItem {
  isHidden: boolean;
  pageItem: IContentPageBase;
  isEllipse: boolean;
  isLink: boolean;
  constructor(isHidden: boolean, pageItem: IContentPageBase, isEllipse: boolean, isLink: boolean) {
    this.isHidden = isHidden;
    this.pageItem = pageItem;
    this.isEllipse = isEllipse;
    this.isLink = isLink;
  }
}

class seoItem {
  '@type': string;
  position: number;
  item: item;
  constructor(type: string, position: number, item: item) {
    this['@type'] = type;
    this.position = position;
    this.item = item;
  }
}

class item {
  '@id': string;
  name: string;
  constructor(id: string, name: string) {
    this['@id'] = id;
    this.name = name;
  }
}

class breadcrumbsResult {
  breadcrumbItems: breadcrumbItem[];
  seoItems: seoItem[];
}

function findItemFromSiteHierarchy(source: IContentPageBase, id: string): IContentPageBase {
  var itemFound = null;
  if (source !== null && id !== null && source.id !== id) {
    if (source.id?.toLowerCase() === id?.toLowerCase()) {
      itemFound = source;
    } else {
      for (const child of source.children) {
        itemFound = findItemFromSiteHierarchy(child, id);
        if (itemFound && itemFound.id?.toLowerCase() === id?.toLowerCase()) {
          break;
        } else {
          itemFound = null;
        }
      }
    }
  }
  // If no match is found, fall back to the top node
  return itemFound !== null ? itemFound : source;
}

const Breadcrumb = ({ sitecoreContext }: BreadcrumbsProps): JSX.Element => {
  function getHomeNode() {
    return sitecoreContext.custom?.siteHierarchy?.homeNode;
  }

  const [hiddenClass, sethiddenClass] = useState<string>('hidden-li');
  const [ellipsesClass, setEllipsesClass] = useState<string>('ellipses-active');

  var id = '{' + sitecoreContext.route.itemId + '}';

  const [currentBreadcrumbNode, setCurrentBreadcrumbNode] = useState<IContentPageBase>(
    findItemFromSiteHierarchy(getHomeNode(), id)
  );

  const context = React.createContext(sitecoreContext);
  const currentContext = useContext(context);

  useEffect(() => {
    var currentId = '{' + currentContext.route.itemId + '}';
    var currentNode = findItemFromSiteHierarchy(getHomeNode(), currentId);
    setCurrentBreadcrumbNode(currentNode);
  }, [currentContext]);

  function getParents() {
    var parentID = currentBreadcrumbNode.parentID?.split('|');
    var parentList = [];
    parentList.push(currentBreadcrumbNode);
    if (parentID?.length > 0) {
      for (let index = 0; index < parentID.length; index++) {
        parentList.push(findItemFromSiteHierarchy(getHomeNode(), parentID[index]));
      }
    } else {
      parentList.push(findItemFromSiteHierarchy(getHomeNode(), currentBreadcrumbNode.parentID));
    }
    parentList.reverse();
    var collectItems = [];
    var seoListElements = [];
    let ellipseDisplayed = false;
    for (let index = 0; index < parentList.length; index++) {
      const element = parentList[index];
      if (parentList.length >= 6 && index > 2 && index < parentList.length - 2) {
        if (!ellipseDisplayed) {
          ellipseDisplayed = true;
          collectItems.push(new breadcrumbItem(true, element, true, false));
        } else {
          collectItems.push(new breadcrumbItem(true, element, false, true));
        }
      } else {
        var isLink = true;
        if (index === parentList.length - 1) {
          isLink = false;
        }
        collectItems.push(new breadcrumbItem(false, element, false, isLink));
      }

      let fullpath = sitecoreContext?.custom?.targetHostName;
      fullpath = fullpath?.replace('http://', 'https://');
      let pathParts = fullpath?.split('/');
      let appendedUrl = pathParts?.[0] + '//' + pathParts?.[2] + element.url;

      seoListElements.push(
        new seoItem('ListItem', index + 1, new item(appendedUrl, element.breadcrumbTitle))
      );
    }
    let finalResult = new breadcrumbsResult();
    finalResult.breadcrumbItems = collectItems;
    finalResult.seoItems = seoListElements;
    return finalResult;
  }

  var isHomePage = getHomeNode()?.id?.toLowerCase() === currentBreadcrumbNode?.id?.toLowerCase();
  var pageExcludedFromBreadcrumb = sitecoreContext?.route?.fields['Exclude From Breadcrumb']?.value
    ? sitecoreContext?.route?.fields['Exclude From Breadcrumb']?.value
    : false;

  var breadcrumbs = getParents();

  const Breadcrumb_Schema = JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.seoItems,
  });

  function getEllipses(item: IContentPageBase) {
    return (
      <>
        <ListItem className={ellipsesClass} key="ellipses-breadcrumb">
          <Tooltip title="Expand to display full path.">
            <a
              href="#"
              onClick={() => {
                sethiddenClass('active-li');
                setEllipsesClass('ellipses-hidden');
              }}
            >
              {'...'}
            </a>
          </Tooltip>
          {' > '}
        </ListItem>
        {getBreadcrumbHidden(item)}
      </>
    );
  }

  function getBreadcrumbHidden(item: IContentPageBase) {
    return (
      <>
        <ListItem className={hiddenClass} key={item.id}>
          <a href={item.url}>
            <ListItemText primary={item.breadcrumbTitle} />
          </a>
          {' > '}
        </ListItem>
      </>
    );
  }

  function getBreadcrumbWithoutLink(item: IContentPageBase) {
    return (
      <>
        <ListItem className="current-page" key={item.id}>
          <ListItemText primary={item.breadcrumbTitle} />
        </ListItem>
      </>
    );
  }

  function getBreadcrumbWithLink(collectItem: breadcrumbItem) {
    if (collectItem.isHidden) {
      return getBreadcrumbHidden(collectItem.pageItem);
    } else {
      return (
        <>
          <ListItem key={collectItem.pageItem.id}>
            <a href={collectItem.pageItem.url}>
              <ListItemText primary={collectItem.pageItem.breadcrumbTitle} />
            </a>
            {' > '}
          </ListItem>
        </>
      );
    }
  }

  if (!isHomePage && !pageExcludedFromBreadcrumb) {
    return (
      <div className="container breadcrumbs-wrapper">
        <div className="breadcrumbs">
          <nav aria-label="breadcrumbs">
            <List className="navigation">
              {breadcrumbs.breadcrumbItems?.map((collectItem: breadcrumbItem) => {
                if (!collectItem.pageItem.isBreadcrumbExclude) {
                  if (collectItem.isEllipse) {
                    return getEllipses(collectItem.pageItem);
                  } else if (collectItem.isLink) {
                    return getBreadcrumbWithLink(collectItem);
                  } else {
                    return getBreadcrumbWithoutLink(collectItem.pageItem);
                  }
                }
                return <></>;
              })}
            </List>
          </nav>
          <div className="clear"></div>
          <Helmet>
            <script className="structured-data-list" type="application/ld+json">
              {Breadcrumb_Schema}
            </script>
          </Helmet>
        </div>
      </div>
    );
  }
  return <></>;
};

export default withSitecoreContext()(Breadcrumb);

import React from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { ICustomContext } from '../../../model/ICustomContext';
import { IContentPageBase } from '../../../model/IContentPageBase';
import { simpleUniqueId } from '../../../utils/helper';
import './index.scss';
import { useTranslation } from 'react-i18next';

type LeftNavigationProps = {
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    route: any;
    custom: ICustomContext;
    itemId: string;
  };
};

const LeftNavigation = ({ sitecoreContext }: LeftNavigationProps): JSX.Element => {
  var datasource: IContentPageBase | null = null;
  var source = sitecoreContext.custom.siteHierarchy.homeNode;
  var id = '{' + sitecoreContext.itemId + '}';
  datasource = findItemFromSiteHierarchy(source, id);
  var parentNavID = datasource?.parentNavID;
  const { t, i18n } = useTranslation();

  if (parentNavID !== '') {
    datasource = findItemFromSiteHierarchy(source, parentNavID);
  } else if (!datasource?.setasParentPage) {
    var parents = datasource?.parentID.split('|');
    if (parents) {
      for (let node of parents) {
        var parentNode = findItemFromSiteHierarchy(source, node);
        if (parentNode?.setasParentPage) {
          datasource = parentNode;
          break;
        }
      }
    }
  }

  function findItemFromSiteHierarchy(
    source: IContentPageBase,
    id: string | undefined
  ): IContentPageBase | null {
    var itemFound = null;
    if (id === undefined) return itemFound;
    if (source !== null) {
      if (source.id?.toLowerCase() === id?.toLowerCase()) {
        itemFound = source;
      } else {
        for (const child of source.children) {
          itemFound = findItemFromSiteHierarchy(child, id);
          if (itemFound) {
            break;
          }
        }
      }
    }
    return itemFound;
  }

  function getLink(page: IContentPageBase) {
    if (page.id.toLowerCase().indexOf(sitecoreContext.itemId) > 0) {
      return <div className="active">{page.navTitle}</div>;
    }
    return <a href={page.url}>{page.navTitle}</a>;
  }

  function getChildNavigation(page: IContentPageBase) {
    var showChild = page.children.length > 0;
    var isExpanded = false;
    for (let child of page.children) {
      if (!child.isNavExclude) {
        showChild = true;
        break;
      }
      showChild = false;
    }

    var template = <div className="linklistitem">{getLink(page)}</div>;
    if (!page.isNavChildrenExclude && showChild) {
      // check if the current page is in the active path of accordion
      if (sitecoreContext.custom?.targetHostName?.includes(page.url)) {
        isExpanded = true;
      }
      template = (
        <MuiAccordion defaultExpanded={isExpanded}>
          {getAccordionSummary(page)}
          {getAccordionDetails(page)}
        </MuiAccordion>
      );
    }
    return template;
  }

  function getAccordionSummary(page: IContentPageBase) {
    if (!page.isNavExclude) {
      return (
        <MuiAccordionSummary
          expandIcon={<ArrowRightIcon />}
          aria-controls={simpleUniqueId('panel-content-')}
          id={simpleUniqueId('panel-header-')}
        >
          <span className="left-nav-heading">{getLink(page)}</span>
        </MuiAccordionSummary>
      );
    }
    return <></>;
  }

  function getAccordionDetails(page: IContentPageBase) {
    const details = page?.children.map((data: IContentPageBase, id: number) => {
      if (data.isNavExclude) {
        return <></>;
      }
      return (
        <li className="linklistitem" key={id}>
          {getChildNavigation(data)}
        </li>
      );
    });

    return (
      <MuiAccordionDetails>
        {details.length ? <ul className="linklist">{details}</ul> : <></>}
      </MuiAccordionDetails>
    );
  }

  if (datasource !== null) {
    return (
      <div className="left-navigation">
        <div className="skipnavigation">
          <a href="#center-content">{t('skip-navigation-text')}</a>
        </div>
        <div>
          <div className="section-title">
            <span>{getLink(datasource)}</span>
          </div>
          <div className="left-navigation-wrapper">{getAccordionDetails(datasource)}</div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default withSitecoreContext()(LeftNavigation);

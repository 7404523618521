import React from 'react';

export interface ISubspecialty {
  displayName: string;
}

export const SubspecialtyTitle = ({ fields }: any): JSX.Element => {
  return (
    <>
      {fields.Subspecialties?.length > 0 &&
        fields.Subspecialties?.map((subspecialty: ISubspecialty, i: any) => (
          <li key={i}>{subspecialty.displayName}</li>
        ))}
      {fields.Subspecialty?.length === 0 && fields.Subspecialty && (
        <li>{fields.Subspecialty.value}</li>
      )}
    </>
  );
};

export default SubspecialtyTitle;

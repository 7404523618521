import React, { useEffect } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Grid from '@material-ui/core/Grid';

import './index.scss';
import {
  buildSearchBox,
  buildResultList,
  buildPager,
  buildQuerySummary,
  buildDidYouMean,
  buildFacet,
  buildSort,
  loadSearchAnalyticsActions,
  loadSearchActions,
  loadAdvancedSearchQueryActions,
  Sort as HeadlessSort,
  SortOrder,
  SortCriterion,
  buildFieldSortCriterion,
} from '@coveo/headless';
import config from '../../../temp/config';
import HeadlessEngine from '../lib/engine';
import { ResultList } from '../lib/resultlist';
import { Pager } from '../lib/pager';
import { QuerySummary } from '../lib/querysummary';
import { DidYouMean } from '../lib/didyoumean';
import { UrlManager } from '../lib/utils';
import { PhysicianResult } from '../lib/resulttemplates/physicianresult';
import { SearchBox } from '../lib/searchbox';
import { Facet } from '../lib/facet';
import { Sort } from '../lib/sort';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

type SearchPageProps = {
  fields: {
    Heading: Field<string>;
    PlaceholderText: Field<string>;
    NoResults: Field<any>;
    QuerySummaryText: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: any };
};

const headlessEngine = new HeadlessEngine(config.coveoQueryPipeline, 'Physicians').engine;
const SearchPage = ({ fields, sitecoreContext }: SearchPageProps): JSX.Element => {
  const Location = useLocation();
  const searchBox = buildSearchBox(headlessEngine);
  const querySummary = buildQuerySummary(headlessEngine);
  const didYouMean = buildDidYouMean(headlessEngine);
  const criteria: [string, SortCriterion][] = [
    ['Last Name (Ascending)', buildFieldSortCriterion('lastz32xname', SortOrder.Ascending)],
  ];
  const initialCriterion = criteria[0][1];
  const controller = buildSort(headlessEngine, {
    initialState: { criterion: initialCriterion },
  });
  const { t, i18n } = useTranslation();

  const resultList = buildResultList(headlessEngine, {
    options: {
      fieldsToInclude: [
        'physicianimageurl',
        'physicianimagealt',
        'physicianlocations',
        'physicianphonenumbers',
        'physicianspecialties',
        'physiciansubspecialties',
        'displayz32xname',
      ],
    },
  });
  const pager = buildPager(headlessEngine);
  const specFacetDesktop = buildFacet(headlessEngine, {
    options: {
      field: 'physicianspecialties',
      facetId: 'specFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });
  const specFacetMobile = buildFacet(headlessEngine, {
    options: {
      numberOfValues: 5,
      field: 'physicianspecialties',
      facetId: 'specFacetMobile',
      sortCriteria: 'alphanumeric',
    },
  });

  const locFacetDesktop = buildFacet(headlessEngine, {
    options: {
      field: 'physicianlocations',
      facetId: 'locFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });
  const locFacetMobile = buildFacet(headlessEngine, {
    options: {
      numberOfValues: 5,
      field: 'physicianlocations',
      facetId: 'locFacetMobile',
      sortCriteria: 'alphanumeric',
    },
  });

  function getQueryExpression(sitecoreContext: any) {
    const database = sitecoreContext?.route?.databaseName;
    var currentIndex = 'Coveo_' + database + '_index';
    var siteName = sitecoreContext?.site?.name;
    var sysSource = currentIndex + ' - ' + config.coveoFarmName;
    var currentLanguage = sitecoreContext?.language;
    var languageQuery = '@language==' + currentLanguage;
    var versionQuery = ' @z95xlatestversion==1';
    var siteQuery = ' @site==' + siteName;
    var sourceQuery = ' @syssource=="' + sysSource;
    var templateQuery = '" @z95xtemplatename==("Physician Page")';
    var hideFromSearch = ' @hidez32xfromz32xsearch==0';
    var nonMediaQuery =
      '(' +
      languageQuery +
      versionQuery +
      siteQuery +
      sourceQuery +
      templateQuery +
      hideFromSearch +
      ')';
    var queryExpression = '(' + nonMediaQuery + ')';
    return queryExpression;
  }

  useEffect(() => {
    const { logInterfaceLoad } = loadSearchAnalyticsActions(headlessEngine);
    const { executeSearch } = loadSearchActions(headlessEngine);

    const cqaction = loadAdvancedSearchQueryActions(headlessEngine).registerAdvancedSearchQueries({
      cq: getQueryExpression(sitecoreContext),
    });

    headlessEngine.dispatch(cqaction);
    headlessEngine.dispatch(executeSearch(logInterfaceLoad()));
    UrlManager(headlessEngine);
  });

  // clears, submits new search which defaults to all. If user is navigating specialists, the clear will not occur
  useEffect(() => {
    const SpecialistsSubfolder = /\/specialist[s|i]/;
    var currentUrl = window.location.href;
    if (!SpecialistsSubfolder.test(window.location.pathname)) {
      // update the search value and submit a new search
      searchBox.updateText('');
      searchBox.clear();
      // cleans up sort query in url that travels into next page
      currentUrl = currentUrl.split('#')[0];
      window.location.href = currentUrl;
    }
  }, [Location]);

  return (
    <div className="headless-consultants-search-page">
      {sitecoreContext.pageEditing && (
        <>
          <p>Physicians SearchPage Component Edit Mode</p>
        </>
      )}

      <div className="search-container">
        <div className="search-box-container">
          <div className="hero-search-overlay-container">
            <div className="search-border-spacer">
              <SearchBox controller={searchBox} placeholderText={fields?.PlaceholderText.value} />
            </div>
          </div>
        </div>
        <Grid container alignItems="stretch" className="two-column-content-row content-row" xs={12}>
          <Grid item xs={12} md={3}>
            <div className="facetDesktop">
              <Facet
                controller={specFacetDesktop}
                title={t('physician-specialties-facet-title')}
                showCount={false}
              />
            </div>
            <div className="facetMobile">
              <Facet
                controller={specFacetMobile}
                title={t('physician-specialties-facet-title')}
                showCount={false}
              />
            </div>
            <div className="facetDesktop">
              <Facet
                controller={locFacetDesktop}
                title={t('physician-locations-facet-title')}
                showCount={false}
              />
            </div>
            <div className="facetMobile">
              <Facet
                controller={locFacetMobile}
                title={t('physician-locations-facet-title')}
                showCount={false}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={9} direction="row" spacing={1}>
            <div className="mainresults-area consultant-listing">
              <Grid container alignItems="stretch">
                <div className="search-title">
                  <QuerySummary controller={querySummary} title={fields?.QuerySummaryText?.value} />
                  <DidYouMean controller={didYouMean} />
                </div>
                <ResultList
                  controller={resultList}
                  hostName={sitecoreContext?.custom?.targetHostName}
                  engine={headlessEngine}
                  noResults={fields?.NoResults.value}
                >
                  <PhysicianResult engine={headlessEngine}></PhysicianResult>
                </ResultList>
              </Grid>
            </div>
            <div className="footer-area">
              <Pager controller={pager} />
            </div>
            <div className="hidden">
              <Sort controller={controller} criteria={criteria} />;
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SearchPage);

import React from 'react';
import {
  ComponentRendering,
  Field,
  LinkField,
  LinkFieldValue,
  RouteData,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { ICustomContext } from '../../model/ICustomContext';
import { IContentPageBase } from '../../model/IContentPageBase';
import './index.scss';

type SubSiteNavProps = {
  fields: SubNavFieldsProps;
  sitecoreContext: SubNavContextProps;
};

type SubNavFieldsProps = {
  RootFolder: Field<LinkFieldValue>;
  MultiColumnView: Field<boolean>;
};

type SubNavContextProps = SitecoreContext & {
  pageEditing: boolean;
  route: any;
  custom: ICustomContext;
  itemId: string;
};

function getLink(page: IContentPageBase, sitecoreContext: SubNavContextProps) {
  if (page?.id?.toLowerCase().indexOf(sitecoreContext.itemId) > 0) {
    return <span className="active">{page.navTitle}</span>;
  }
  return <a href={page.url}>{page.navTitle}</a>;
}

function getNavSummary(page: IContentPageBase, sitecoreContext: SubNavContextProps) {
  if (!page.isNavExclude) {
    return (
      <>
        <span className="left-nav-heading">{getLink(page, sitecoreContext)}</span>
      </>
    );
  }
  return <></>;
}
function getChildNavigation(
  page: IContentPageBase,
  sitecoreContext: SubNavContextProps,
  fields: SubNavFieldsProps
) {
  var showChild = page.children.length > 0;
  for (let child of page.children) {
    if (!child.isNavExclude) {
      showChild = true;
      break;
    }
    showChild = false;
  }

  var template = <span className="linklistitem">{getLink(page, sitecoreContext)}</span>;
  if (!page.isNavChildrenExclude && showChild) {
    template = (
      <>
        {' '}
        {getNavSummary(page, sitecoreContext)}
        {getNavDetails(page, sitecoreContext, fields)}
      </>
    );
  }
  return template;
}
function getNavDetails(
  page: IContentPageBase,
  sitecoreContext: SubNavContextProps,
  fields: SubNavFieldsProps
) {
  const details = page?.children.map((data: IContentPageBase, id: number) => {
    if (data.isNavExclude) {
      return <></>;
    }
    return (
      <li className="linklistitem" key={id}>
        {getChildNavigation(data, sitecoreContext, fields)}
      </li>
    );
  });

  if (fields.MultiColumnView.value === true) {
    return <>{details.length ? <ul className="linklist linklist-special">{details}</ul> : <></>}</>;
  }
  return <>{details.length ? <ul className="linklist">{details}</ul> : <></>}</>;
}

function findItemFromSiteHierarchy(
  source: IContentPageBase,
  id: string | undefined
): IContentPageBase | null {
  var itemFound = null;
  if (id === undefined) return itemFound;
  if (source !== null) {
    // left off here
    if (source.id?.toLowerCase() === id.toString().toLowerCase()) {
      itemFound = source;
    } else {
      for (const child of source.children) {
        itemFound = findItemFromSiteHierarchy(child, id);
        if (itemFound) {
          break;
        }
      }
    }
  }
  return itemFound;
}

const SubSiteNav = ({ fields, sitecoreContext }: SubSiteNavProps): JSX.Element => {
  var datasource: IContentPageBase | null = null;
  const datasourceId = fields?.RootFolder?.value?.id;
  var source = sitecoreContext.custom.siteHierarchy.homeNode;
  var id: string | undefined;
  if (typeof datasourceId === 'string' || typeof datasourceId === 'undefined') {
    id = datasourceId;
  }
  datasource = findItemFromSiteHierarchy(source, id);
  var parentNavID = datasource?.parentNavID.toString();

  if (parentNavID !== '') {
    datasource = findItemFromSiteHierarchy(source, parentNavID);
  } else if (!datasource?.setasParentPage) {
    var parents = datasource?.parentID.split('|');
    if (parents) {
      for (let node of parents) {
        var parentNode = findItemFromSiteHierarchy(source, node);
        if (parentNode?.setasParentPage) {
          datasource = parentNode;
          break;
        }
      }
    }
  }
  if (fields && datasource !== null) {
    return (
      <div className="">
        <div>
          {sitecoreContext.pageEditing && (
            <>
              <p>SubSiteNav Component Edit Mode</p>
            </>
          )}
          <div className="section-title">
            <strong>
              <span>{getLink(datasource, sitecoreContext)}</span>
            </strong>
          </div>
          <div className="left-navigation-wrapper">
            {getNavDetails(datasource, sitecoreContext, fields)}
          </div>
        </div>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(SubSiteNav);

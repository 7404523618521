// @ts-nocheck
import React from 'react';
import { createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import RawHtml from '../RawHtml';
import GoogleRecaptcha from '../GoogleRecaptcha';

enum MyFieldTypes {
  RawHtml = '{95DDE898-F4DE-4359-A985-90128FE45CC8}',
  GoogleRecaptcha = '{6ABDE06F-C050-4829-BADE-BAC444F19191}',
}

function setComponent<FieldProps>(
  type: FieldTypes | string,
  component: FormFieldComponent<TProps>
) {
  this._fieldMap.set(type, component);
}

const customFieldFactory = createDefaultFieldFactory();
customFieldFactory.setComponent(MyFieldTypes.RawHtml, (props) => <RawHtml {...props} />);
customFieldFactory.setComponent(MyFieldTypes.GoogleRecaptcha, (props) => (
  <GoogleRecaptcha {...props} />
));

export default customFieldFactory;

import React from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';

type ContactPageSchemaProps = {
  fields: {
    'Location Name': Field<string>;
  };
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    route: {
      fields: {
        'Meta Description': Field<string>;
      };
    };
  };
};

const ContactPageSchema = ({ fields, sitecoreContext }: ContactPageSchemaProps): JSX.Element => {
  if (!sitecoreContext || sitecoreContext.pageEditing) {
    return <p>Contact Page Schema Included</p>;
  }
  let pathname = typeof window !== 'undefined' ? window.location.href : '';
  let locationName = fields?.['Location Name']?.value;
  let metaDescription = sitecoreContext?.route?.fields?.['Meta Description']?.value;

  const Contact_Schema = JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'ContactPage',
    name: `${locationName}`,
    description: `${metaDescription}`,
    url: `${pathname}`,
    potentialAction: ['Submit'],
  });
  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {Contact_Schema}
      </script>
    </Helmet>
  );
};
export default withSitecoreContext()(ContactPageSchema);

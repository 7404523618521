import './index.scss';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Field,
  RichText,
  LinkFieldValue,
  ImageField,
  SitecoreContext,
  withSitecoreContext,
  RouteData,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-react';

type ImageCardProps = {
  fields: {
    Header: Field<string>;
    Description: Field<string>;
    Image: ImageField;
    ButtonLink: Field<LinkFieldValue>;
  };
  rendering: RouteData | ComponentRendering;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const ImageCard = ({ fields, sitecoreContext }: ImageCardProps): JSX.Element => {
  let Heading = fields?.Header;
  let Description = fields?.Description;
  let Image = fields?.Image;
  let buttonLink = fields?.ButtonLink;

  if (fields) {
    return (
      <div className="imagecard-wrapper">
        {sitecoreContext.pageEditing && (
          <>
            <p>ImageCard Component Edit Mode</p>
          </>
        )}
        {
          <>
            <div className="imagecard">
              <Card>
                {buttonLink?.value?.href ? (
                  <CardActionArea className="imagecard-aa" href={buttonLink?.value?.href}>
                    {Image?.value ? (
                      <CardMedia className="imagecard-image" image={Image?.value?.src} />
                    ) : null}
                    <CardContent className="imagecard-header-wrapper">
                      {Heading?.value ? (
                        <Typography className="imagecard-heading" component="h5">
                          {Heading?.value}
                        </Typography>
                      ) : null}
                      {Description?.value ? (
                        <Typography
                          className="imagecard-desc"
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <RichText field={Description} />
                        </Typography>
                      ) : null}
                    </CardContent>
                  </CardActionArea>
                ) : (
                  <CardActionArea className="imagecard-aa">
                    {Image?.value ? (
                      <CardMedia className="imagecard-image" image={Image?.value?.src} />
                    ) : null}
                    <CardContent className="imagecard-header-wrapper">
                      {Heading?.value ? (
                        <Typography className="imagecard-heading" component="h5">
                          {Heading?.value}
                        </Typography>
                      ) : null}
                      {Description?.value ? (
                        <Typography
                          className="imagecard-desc"
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <RichText field={Description} />
                        </Typography>
                      ) : null}
                    </CardContent>
                  </CardActionArea>
                )}
                {buttonLink?.value?.href ? (
                  <CardActions>
                    <a className="imagecard-link-container" href={buttonLink?.value?.href}>
                      <Button className="imagecard-button" size="small" color="primary">
                        {buttonLink?.value?.text}
                      </Button>
                    </a>
                  </CardActions>
                ) : null}
              </Card>
            </div>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};
export default withSitecoreContext()(ImageCard);

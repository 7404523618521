import React from 'react';
import {
  withSitecoreContext,
  Placeholder,
  RouteData,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid, Container } from '@material-ui/core';

import './index.scss';

type RenderingProps = {
  rendering: RouteData | ComponentRendering;
  sitecoreContext: any;
};

const JobPostingDetails = (
  { rendering, sitecoreContext }: RenderingProps,
  route: RouteData
): JSX.Element => {
  return (
    <>
      {/* <h1>{sitecoreContext.route.fields?.Title?.value}</h1> */}
      <Container maxWidth={false} disableGutters>
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Placeholder name="job_posting_details_content" rendering={rendering} />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} disableGutters>
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Placeholder name="job_posting_details_disclaimer" rendering={rendering} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withSitecoreContext()(JobPostingDetails);

import React from 'react';
import {
  Text,
  Field,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

type ConsentCheckerProps = {
  fields: {
    Heading: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const ConsentChecker = ({ fields, sitecoreContext }: ConsentCheckerProps): JSX.Element => {
  if (fields) {
    return (
      <div className="consentchecker">
        {/* {sitecoreContext.pageEditing && (
          <>
            <p>ConsentChecker Component Edit Mode</p>
          </>
        )}
        <Text field={fields.Heading} /> */}
      </div>
    );
  }
  // else if (!fields && sitecoreContext.pageEditing) {
  //   return <p>Please setup the Datasource.</p>;
  // }
  return <></>;
};

export default withSitecoreContext()(ConsentChecker);

import React from 'react';
import {
  Field,
  ImageField,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';

type HospitalPageSchemaProps = {
  fields: {
    'Location Name': Field<string>;
    Image: {
      value: {
        src: string;
      };
    };
    'Opening Hours': Field<string>;
    'Street Address 1': Field<string>;
    'Street Address 2': Field<string>;
    'Street Address 3': Field<string>;
    City: Field<string>;
    'Postal Code': Field<string>;
    'Phone Number': Field<string>;
    'Fax Number': Field<string>;
    Email: Field<string>;
    'Country Code': Field<string>;
    Departments: [HospitalPageSchemaProps];
  };
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
  };
};

const HospitalPageSchema = ({ fields, sitecoreContext }: HospitalPageSchemaProps): JSX.Element => {
  if (!sitecoreContext || sitecoreContext.pageEditing) {
    return <p>Hospital Page Schema Included</p>;
  }
  let pathname = typeof window !== 'undefined' ? window.location.href : '';
  let locationName = fields?.['Location Name']?.value;
  let streetAddress1 = fields?.['Street Address 1']?.value;
  let streetAddress2 = fields?.['Street Address 2']?.value;
  let streetAddress3 = fields?.['Street Address 3']?.value;
  let city = fields?.City?.value;
  let postalCode = fields?.['Postal Code']?.value;
  let countryCode = fields?.['Country Code']?.value;
  let phone = fields?.['Phone Number']?.value;
  let fax = fields?.['Fax Number']?.value;
  let email = fields?.Email?.value;
  let openingHours = fields?.['Opening Hours']?.value;
  let Depts = {};
  let image = imageCheck(fields?.Image?.value?.src);

  function imageCheck(imageToCheck: string | undefined) {
    if (imageToCheck === undefined) {
      imageToCheck = '';
    }
    return imageToCheck;
  }

  function DepartmentsSchema() {
    if (fields.Departments.length > 0) {
      Depts = fields.Departments.map((location: HospitalPageSchemaProps, i: number) =>
        JSON.parse(
          JSON.stringify({
            '@type': 'Hospital',
            name: `${location?.fields?.['Location Name']?.value}`,
            openingHours: `${location?.fields?.['Opening Hours']?.value}`,
            image: imageCheck(location?.fields?.Image?.value?.src),
            telephone: `${location?.fields?.['Phone Number']?.value}`,
            faxNumber: `${location?.fields?.['Fax Number']?.value}`,
            email: `${location?.fields?.Email?.value}`,
            address: {
              '@type': 'PostalAddress',
              streetAddress: `${location?.fields?.['Street Address 1']?.value}`,
              addressLocality: `${location?.fields?.['Street Address 3']?.value}`,
              addressRegion: `${location?.fields?.City?.value}`,
              postalCode: `${location?.fields?.['Postal Code']?.value}`,
              addressCountry: `${location?.fields?.['Country Code']?.value}`,
            },
          })
        )
      );
    }
    return Depts;
  }
  const Hospital_Schema = JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'Hospital',
    name: `${locationName}`,
    openingHours: `${openingHours}`,
    url: `${pathname}`,
    image: `${image}`,
    telephone: `${phone}`,
    faxNumber: `${fax}`,
    email: `${email}`,
    address: {
      '@type': 'PostalAddress',
      streetAddress: `${streetAddress1}`,
      addressLocality: `${streetAddress3}`,
      addressRegion: `${city}`,
      postalCode: `${postalCode}`,
      addressCountry: `${countryCode}`,
    },
    department: DepartmentsSchema(),
  });
  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {Hospital_Schema}
      </script>
    </Helmet>
  );
};

export default withSitecoreContext()(HospitalPageSchema);

import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';
import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import { sitecoreApiHost, sitecoreApiKey } from '../../../temp/config';
import { isCookiesAllowed } from '../../../utils/helper';
import customFieldFactory from '../CustomFieldFactory';
import CustomFieldValidationErrors from '../CustomFieldValidationErrors';
import './index.scss';

const ReactSitecoreForm = (props: any): JSX.Element => {
  const [requestMessage, setRequestMessage] = useState(null);

  if (typeof window !== 'undefined') {
    // Context required to grab the request message from sitecore.
    const sitecoreContextFactory = useContext(SitecoreContextReactContext);

    useEffect(() => {
      const requestExists = isCookiesAllowed();
      const forms = Array.from(document.querySelectorAll('.upmc-form form'));
      // checking if sxa_site is there, otherwise disabling the form.
      if (requestExists === false) {
        forms.forEach((form) => {
          Array.from(
            form.querySelectorAll(
              'input, select, button, textarea, fieldset, datalist, option, optgroup'
            )
          ).forEach((element) => {
            element.setAttribute('disabled', '');
          });
        });
        setRequestMessage(
          sitecoreContextFactory.context.custom.siteConfiguration.formsCookieMessage
        );
      }
    }, [sitecoreContextFactory]);
  }

  if (props.fields) {
    return (
      <div className="form-container">
        {requestMessage && <p className="request-denied">{requestMessage}</p>}
        <div className={props.fields.metadata.cssClass}>
          <Form
            fieldFactory={customFieldFactory}
            fieldValidationErrorsComponent={CustomFieldValidationErrors}
            form={props.fields}
            sitecoreApiHost={sitecoreApiHost}
            sitecoreApiKey={sitecoreApiKey}
            onRedirect={(url) => props.history.push(url)}
          ></Form>
        </div>
      </div>
    );
  }
  return <></>;
};

export default withRouter(ReactSitecoreForm);

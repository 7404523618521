import React from 'react';
import {
  RichText,
  Field,
  ImageField,
  LinkFieldValue,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import { ResponsiveImage, ResponsiveImageProps } from '../ResponsiveImage';
import { Logo, LogoProps } from '../Logo';
import './index.scss';

type LogoBannerProps = {
  fields: {
    Heading: Field<string>;
    'Logo Image': ImageField;
    'Desktop Image': ImageField;
    'Mobile Image': ImageField;
    'Tablet Image': ImageField;
    Link: Field<LinkFieldValue>;
    Direction: Field<string>;
    'Contact Text': Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const LogoBanner = ({ fields, sitecoreContext }: LogoBannerProps): JSX.Element => {
  if (fields) {
    let logoObj: LogoProps = {
      fields: {
        'Logo Image': fields['Logo Image'],
        Link: fields.Link,
      },
      sitecoreContext: sitecoreContext,
      params: {
        Direction: fields.Direction,
      },
    };
    let imageObj: ResponsiveImageProps = {
      responsiveFields: {
        MobileImageSrc: fields['Mobile Image'],
        TabletImageSrc: fields['Tablet Image'],
        DesktopImageSrc: fields['Desktop Image'],
      },
      sitecoreContext: sitecoreContext,
    };
    return (
      <div className="logo-banner">
        {
          <>
            <div className="container">
              <div className="right column">
                <ResponsiveImage
                  responsiveFields={imageObj.responsiveFields}
                  sitecoreContext={imageObj.sitecoreContext}
                />
              </div>
              <div className="left column">
                <div className="textarea">
                  <RichText field={fields['Contact Text']} />
                </div>
                <div className="colorbar-mobile"></div>
              </div>
              <div className="logo-image">
                <picture>
                  <img src={fields['Logo Image'].value?.src} />
                </picture>
              </div>
            </div>
            <div className="colorbar"></div>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(LogoBanner);

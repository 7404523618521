import React from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import './index.scss';

interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

export default function HeaderLogoOnly(props: Props) {
  return (
    <div className="header-logo-only">
      <nav aria-label="Primary Navigation">
        <div className="grow utility-navigation">
          <div className="phone-number">
            <Placeholder name="desktop_utility_navigation_phone" rendering={props.rendering} />
          </div>
        </div>
        <div className="logo-container">
          <Placeholder name="logo_container" rendering={props.rendering} />
        </div>
      </nav>
    </div>
  );
}
